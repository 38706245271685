body {
    margin: 0;
    padding: 0;
    font-family: "Roboto Condensed", "Rubik", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
}

* {
    font-family: "Roboto Condensed", "Rubik", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: 0.1s;
    transition-property: auto;
    box-sizing: border-box;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track-piece {
    background-color: transparent;
}

::-webkit-scrollbar-thumb:vertical {
    border-radius: 4px;
    height: 5px;
    background-color: #4b4b4b;
}

::-webkit-scrollbar-thumb:horizontal {
    border-radius: 4px;
    height: 5px;
    background-color: #4b4b4b;
}

.drawer-enter {
    transform: translate(-100%);
}


.drawer-enter-active {
    transform: translate(0);
    transition: transform 0.2s linear;
}

.drawer-exit {
    transform: translate(0);
}

.drawer-exit-active {
    transform: translate(-100%);
    transition: transform 0.2s linear;
}
